import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Box, Divider, Grid, ListSubheader, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import Image from '../../Image';
// hooks
import useLocales from '../../../hooks/useLocales';
import useAuth from '../../../hooks/useAuth';

import { useDispatch, useSelector } from '../../../redux/store';
import { openLoginSignup } from '../../../redux/slices/authPages';
import { openTransactionsDialog } from '../../../redux/slices/transactionsDialog';

import NotificationPopover from '../../../layouts/dashboard/header/NotificationPopover';

import useResponsive from '../../../hooks/useResponsive';
import Iconify from '../../Iconify';
import palette from '../../../theme/palette';
import LanguagePopover from '../../../layouts/dashboard/header/LanguagePopover';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
  onCloseSidebar: PropTypes.func,
};

const ButtonContainer = styled(Stack)(({ theme }) => ({
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',
  height: '43px',
  alignItems: 'center',
  cursor: 'pointer',
}));

const StyledImage = styled(Image)(({ theme }) => ({
  width: 38,
  height: 'auto',
  paddingLeft: 8,
}));

const NavWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '10px',
  cursor: 'pointer',
  padding: '5px 8px',
  position: 'relative',
  height: '100%',
}));

const NavImage = styled(Box)(({ theme }) => ({
  width: '25px',
  height: '25px',
  display: 'flex',
  alignItems: 'center',
}));

const NavBtnText = styled(Typography)(() => ({
  fontSize: '13px',
  fontWeight: 900,
  fontFamily: 'sans-serif',
  lineHeight: '1.5',
  // textAlign: 'center',
  textDecoration: 'none',
  color: palette.navBarVertical.menu.color,
  marginLeft: '16px',
}));

const Title = styled(Box)({
  color: 'white',
  textTransform: 'uppercase',
  fontWeight: 500,
  padding: '20px 0 10px 10px',
});

const TransactionBox = styled(Stack)(({ theme }) => ({
  flex: 1,
  background: 'linear-gradient(180deg, #5E00D5, #4F0075)',
  border: '1px solid #FFB9EB',
  boxShadow: '0px 0px 5px 2px #8000FF',
  borderRadius: 10,
  padding: '6px 4px',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
}));

const ReferralBox = styled(Stack)(({ theme }) => ({
  background: 'linear-gradient(180deg, #5E00D5, #4F0075)',
  border: '1px solid #FFB9EB',
  boxShadow: '0px 0px 5px 2px #8000FF',
  marginTop: 8,
  borderRadius: 10,
  padding: '4px 4px',
  cursor: 'pointer',
}));

const MenuText = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 500,
  color: '#fff',
  textAlign: 'center',
  lineHeight: 'normal',
  marginTop: 5,
}));

const LogoutBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 20,
  left: 0,
  right: 0,
  width: '100%',
  marginTop: '5rem',
  display: 'flex',
}));

const LogoutButton = styled(Stack)(({ theme }) => ({
  width: '100%',
  padding: '5px 8px',
  background: 'slategray',
  margin: '0px 16px',
  borderRadius: 4,
  cursor: 'pointer',
}));

function getTierBackground(tier) {
  switch (tier?.toLowerCase()) {
    case 'bronze':
    case 'normal':
      return { background: '#CD7F32, #fbb978, #CD7F32', border: '#eeb680' };
    case 'silver':
      return { background: 'silver, #fff, silver', border: '#fff' };
    case 'gold':
      return { background: 'gold, #ffee92, gold', border: 'gold' };
    case 'platinum':
      return { background: '#e5e4e2, #c5c5c5, #e5e4e2', border: '#fff' };
    case 'diamond':
      return { background: '#b9f2ff, #62ddf9, #b9f2ff', border: '#fff' };
    default:
      return { background: '#CD7F32, #fbb978, #CD7F32', border: '#eeb680' };
  }
}

export default function NavSectionVertical({ navConfig, isCollapse = false, onCloseSidebar = () => {}, ...other }) {
  const { logout } = useAuth();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const theme = useTheme();

  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const { gameCategsAndCompanies, activeTab, angPauPromos, userProfile, promotions } = useSelector((x) => ({
    ...x.game,
    ...x.lookup,
  }));

  const { siteSettings } = useSelector((x) => x.lookup);

  const isDesktop = useResponsive('up', 'lg');

  const isAuthenticated = localStorage.getItem('accessToken');

  const [referralDepositBonusId, setReferralDepositBonusId] = useState(null);

  useEffect(() => {
    const referralDepositBonusType = promotions?.data?.filter((f) => f.promotion_type === 'referral_deposit_bonus');

    if (referralDepositBonusType?.length > 0) {
      setReferralDepositBonusId(referralDepositBonusType[0]?.id);
    }
  }, [promotions]);

  const openSocial = (link) => {
    window.open(
      link,
      '_blank'
      // 'noopener noreferrer'
    );
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/', { replace: true });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <Box {...other}>
      <Box sx={{ pt: 2, px: 2, pb: 1 }}>
        <Stack
          onClick={() => navigate('/user/account')}
          direction={'row'}
          spacing={1}
          alignItems="center"
          sx={{ cursor: 'pointer' }}
        >
          <Image
            placeholderRatio="50px"
            src={require('../../../assets/home/polar-bear.png')}
            sx={{ width: '50px', height: '50px' }}
          />
          <Stack spacing={0}>
            <Box
              sx={{
                width: 'fit-content',
                background: `linear-gradient(90deg, ${getTierBackground(userProfile?.MemberGroup?.name)?.background})`,
                p: '0px 15px',
                borderRadius: '20px',
                border: `2px solid ${getTierBackground(userProfile?.MemberGroup?.name)?.border}`,
              }}
            >
              <Typography
                sx={{
                  fontSize: '8px',
                  fontWeight: 900,
                  textTransform: 'uppercase',
                  textShadow: '1px 1px 5px rgba(0, 0, 0, .5)',
                }}
              >
                {userProfile?.MemberGroup?.name}
              </Typography>
            </Box>
            <Typography sx={{ color: '#fff', fontWeight: 900, fontSize: '14px' }}>{userProfile?.username}</Typography>
            <Typography sx={{ color: '#fff', fontWeight: 400, fontSize: '12px', lineHeight: 'normal' }}>
              {userProfile?.UserProfile?.no_hp}
            </Typography>
          </Stack>
        </Stack>

        <Stack direction={'row'} spacing={1} sx={{ mt: 2 }}>
          <TransactionBox
            direction="row"
            spacing={1}
            onClick={() => dispatch(openTransactionsDialog({ open: true, isDeposit: true }))}
          >
            <Typography sx={{ color: '#fff', textAlign: 'center', fontSize: '12px', fontWeight: 500 }}>
              {translate('deposit')}
            </Typography>
            <Image
              placeholderRatio="16px"
              src={require('../../../assets/navbar/deposit.webp')}
              sx={{ height: 16, width: 16 }}
            />
          </TransactionBox>
          <TransactionBox
            direction="row"
            spacing={1}
            onClick={() => dispatch(openTransactionsDialog({ open: true, isDeposit: false }))}
          >
            <Typography sx={{ color: '#fff', textAlign: 'center', fontSize: '12px', fontWeight: 500 }}>
              {translate('withdraw')}
            </Typography>
            <Image
              placeholderRatio="16px"
              src={require('../../../assets/navbar/withdraw.webp')}
              sx={{ height: 16, width: 16 }}
            />
          </TransactionBox>
        </Stack>

        <ReferralBox
          direction="row"
          alignItems={'center'}
          spacing={0.5}
          onClick={() => {
            enqueueSnackbar(`${translate('copied')}!`, { variant: 'success', autoHideDuration: 5000 });
            navigator.clipboard.writeText(`${window.location.origin}/auth/register?aff=${userProfile?.referral_code}`);
          }}
        >
          <Iconify icon="stash:share-duotone" sx={{ color: '#fff', width: 20, height: 20 }} />
          <Typography sx={{ fontSize: '9px', color: '#fff', fontWeight: 500, whiteSpace: 'nowrap' }}>
            {translate('referral_link')}
          </Typography>
          <Typography
            sx={{ fontSize: '9px', color: '#fff', whiteSpace: 'nowrap' }}
          >{`${window.location.origin}/auth/register?aff=${userProfile?.referral_code}`}</Typography>
          <Iconify icon="fluent:copy-16-regular" sx={{ color: '#fff', width: 20, height: 20 }} />
        </ReferralBox>

        <Stack spacing={1.5} sx={{ mt: 3 }}>
          <Stack
            direction={'row'}
            alignItems={'center'}
            spacing={2}
            onClick={() => {
              navigate('/vip');
            }}
            sx={{ cursor: 'pointer' }}
          >
            <Image
              placeholderRatio="25px"
              src={require('../../../assets/navbar/crown.png')}
              sx={{ width: 25, height: 25 }}
            />
            <Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#fff', flex: 1, whiteSpace: 'nowrap' }}>
              {translate('vip_member_level')}
            </Typography>
            <Image
              placeholderRatio="25px"
              src={require('../../../assets/navbar/arrow.png')}
              sx={{ width: 20, height: 20 }}
            />
          </Stack>

          <Stack
            direction={'row'}
            alignItems={'center'}
            spacing={2}
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              if (!isAuthenticated) navigate('/auth/login');
              else navigate('/referral');
              // if (referralDepositBonusId) navigate(`/promotion/view/${referralDepositBonusId}`);
              onCloseSidebar();
            }}
          >
            <Image
              placeholderRatio="25px"
              src={require('../../../assets/navbar/referral.png')}
              sx={{ width: 25, height: 25 }}
            />
            <Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#fff', flex: 1, whiteSpace: 'nowrap' }}>
              {translate('referral')}
            </Typography>
            <Image
              placeholderRatio="25px"
              src={require('../../../assets/navbar/arrow.png')}
              sx={{ width: 20, height: 20 }}
            />
          </Stack>

          <Stack
            direction={'row'}
            alignItems={'center'}
            spacing={2}
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate('/promotion')}
          >
            <Image
              placeholderRatio="25px"
              src={require('../../../assets/navbar/present.png')}
              sx={{
                width: 25,
                height: 25,
                img: {
                  objectFit: 'contain',
                  height: 20,
                },
              }}
            />
            <Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#fff', flex: 1, whiteSpace: 'nowrap' }}>
              {translate('promotions')}
            </Typography>
            <Image
              placeholderRatio="25px"
              src={require('../../../assets/navbar/arrow.png')}
              sx={{ width: 20, height: 20 }}
            />
          </Stack>

          <Stack
            direction={'row'}
            alignItems={'center'}
            spacing={2}
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              if (!isAuthenticated) navigate('/auth/login');
              else navigate('/history/transaction');
              // if (referralDepositBonusId) navigate(`/promotion/view/${referralDepositBonusId}`);
              onCloseSidebar();
            }}
          >
            <Image
              placeholderRatio="25px"
              src={require('../../../assets/navbar/transaction_history.png')}
              sx={{
                width: 25,
                height: 25,
                img: {
                  objectFit: 'contain',
                  height: 22,
                },
              }}
            />
            <Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#fff', flex: 1, whiteSpace: 'nowrap' }}>
              {translate('transaction_history')}
            </Typography>
            <Image
              placeholderRatio="25px"
              src={require('../../../assets/navbar/arrow.png')}
              sx={{ width: 20, height: 20 }}
            />
          </Stack>

          <Stack
            direction={'row'}
            alignItems={'center'}
            spacing={2}
            sx={{ cursor: 'pointer', width: '100%' }}
            onClick={() => {
              if (!isAuthenticated) navigate('/auth/login');
              else navigate('/history/bet');
              // if (referralDepositBonusId) navigate(`/promotion/view/${referralDepositBonusId}`);
              onCloseSidebar();
            }}
          >
            <Image
              placeholderRatio="25px"
              src={require('../../../assets/navbar/bet_history.png')}
              sx={{ width: 25, height: 25 }}
            />
            <Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#fff', flex: 1, whiteSpace: 'nowrap' }}>
              {translate('bet_history')}
            </Typography>
            <Image
              placeholderRatio="25px"
              src={require('../../../assets/navbar/arrow.png')}
              sx={{ width: 20, height: 20 }}
            />
          </Stack>

          <Stack
            direction={'row'}
            alignItems={'center'}
            spacing={2}
            sx={{ cursor: 'pointer', width: '100%' }}
            onClick={() => {
              if (!isAuthenticated) navigate('/auth/login');
              else navigate('/user/banks');
              onCloseSidebar();
            }}
          >
            <Image
              placeholderRatio="25px"
              src={require('../../../assets/navbar/bank.png')}
              sx={{
                width: 25,
                height: 25,
                img: {
                  objectFit: 'contain',
                  height: 20,
                },
              }}
            />
            <Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#fff', flex: 1, whiteSpace: 'nowrap' }}>
              {translate('bank_account')}
            </Typography>
            <Image
              placeholderRatio="25px"
              src={require('../../../assets/navbar/arrow.png')}
              sx={{ width: 20, height: 20 }}
            />
          </Stack>

          <NotificationPopover />

          <LanguagePopover isSideNav PopoverProps={{ arrow: 'top-center' }} />
        </Stack>

        <LogoutBox>
          <LogoutButton
            direction="row"
            alignItems={'center'}
            justifyContent={'center'}
            spacing={1}
            onClick={() => handleLogout()}
          >
            <Typography sx={{ color: '#fff', fontWeight: 500, fontSize: '14px' }}>{translate('logout')}</Typography>
            <Iconify icon="heroicons-outline:logout" sx={{ width: 20, height: 20, color: '#fff' }} />
          </LogoutButton>
        </LogoutBox>

        {/* {angPauPromos?.data?.length ? (
          <Box onClick={() => navigate('/angpau')}>
            <Image src={require('../../../assets/menu/nav-earn.png')} />
          </Box>
        ) : (
          ''
        )} */}
      </Box>
    </Box>
  );
}
