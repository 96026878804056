import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isOpenForgotPwd: false,
  isOpenChangePwd: false,
  isPopupOpened: 0,
  isOpenLoginSignupDialog: {
    open: false,
    isLogin: true,
  },
  openForgotPasswordDialog: false,
};

const slice = createSlice({
  name: 'authPages',
  initialState,
  reducers: {
    closeForgotPwd(state) {
      state.isOpenForgotPwd = false;
    },
    openForgotPwd(state) {
      state.isOpenForgotPwd = true;
    },
    closeChangePwd(state) {
      state.isOpenChangePwd = false;
    },
    openChangePwd(state) {
      state.isOpenChangePwd = true;
    },
    openLoginSignup(state, action) {
      state.isOpenLoginSignupDialog = {
        open: action?.payload?.open,
        isLogin: action?.payload?.isLogin,
      };
    },
    setIsPopupOpened(state, action) {
      state.isPopupOpened = action.payload;
    },
    setOpenForgotPasswordDialog(state, action) {
      state.openForgotPasswordDialog = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  openForgotPwd,
  openChangePwd,
  closeForgotPwd,
  closeChangePwd,
  openLoginSignup,
  setIsPopupOpened,
  setOpenForgotPasswordDialog,
} = slice.actions;
