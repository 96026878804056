/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import conObj from '../../utils/connection-assistant';

// ----------------------------------------------------------------------

const initialState = {
  inbox: [],
  unreadInboxCount: 0,
  memberBalance: {
    currencyCode: '',
    amount: 0,
    balance: 0,
    displayBalance: 0,
  },
  gameCategsAndCompanies: [],
  gameCategs: [],
  siteSettings: JSON.parse(localStorage.getItem('site_configs') || '{}') || {},
  promotions: { data: [], categories: [] },
  angPauPromos: { data: [], isFetchedOnce: false },
  withdrawRequirement: {
    calculationType: '',
    current: 0,
    requirement: 0,
    isSufficient: false,
    allowWithdraw: false,
  },
  depositWithdrawMinMax: {
    maxWithdrawLimit: 0,
    minWithdrawLimit: 0,
    currencyWithdraw: {},
    currencyTopup: {},
    withdrawLimitMessage: '',
  },
  memberBanks: [],
  userProfile: {},
};

const slice = createSlice({
  name: 'lookup',
  initialState,
  reducers: {
    setMemberBalance(state, action) {
      state.memberBalance = action.payload;
    },
    setInbox(state, action) {
      state.inbox = action.payload;
    },
    setUnreadInboxCount(state, action) {
      state.unreadInboxCount = action.payload;
    },
    setGameCategsAndCompanies(state, action) {
      state.gameCategsAndCompanies = action.payload;
    },
    setGameCategs(state, action) {
      state.gameCategs = action.payload;
    },
    setSiteSettings(state, action) {
      state.siteSettings = action.payload;
    },
    setPromotions(state, action) {
      state.promotions = action.payload;
    },
    claimPromotion(state, action) {
      const newPromotions = JSON.parse(JSON.stringify(state.promotions?.data));

      if (newPromotions?.length) {
        state.promotions = {
          ...state.promotions,
          data: newPromotions.map((x) => {
            if (action.payload === x?.id) {
              return { ...x, is_claimable_now: false };
            }

            return x;
          }),
        };
      }
    },
    claimPromotionBanner(state, action) {
      const newBanner = JSON.parse(JSON.stringify(state.siteSettings?.sliders));

      if (newBanner?.length) {
        state.siteSettings = {
          ...state.siteSettings,
          sliders: newBanner.map((x) => {
            if (action.payload === x?.BannerSlide?.id) {
              return {
                BannerSlide: {
                  ...x?.BannerSlide,
                  is_claimable_now: false,
                },
              };
            }

            return x;
          }),
        };
      }
    },
    setAngPauPromos(state, action) {
      state.angPauPromos = action.payload;
    },
    setFakePlayerStats(state, action) {
      state.fakePlayerStats = action.payload;
    },
    setWithdrawRequirement(state, action) {
      state.withdrawRequirement = action.payload;
    },
    setMemberBanks(state, action) {
      state.memberBanks = action.payload;
    },
    setDepositWithdrawMinMax(state, action) {
      state.depositWithdrawMinMax = action.payload;
    },
    setUserProfile(state, action) {
      state.userProfile = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setMemberBalance,
  setInbox,
  setUnreadInboxCount,
  setGameCategsAndCompanies,
  setGameCategs,
  setSiteSettings,
  setPromotions,
  claimPromotion,
  claimPromotionBanner,
  setAngPauPromos,
  setFakePlayerStats,
  setWithdrawRequirement,
  setMemberBanks,
  setDepositWithdrawMinMax,
  setUserProfile,
} = slice.actions;

// export function callMultipleRequests() {
//   const requestData = {
//     'requests[0]': JSON.stringify({
//       url: 'setting/site_configs.json',
//       extra_input: '',
//     }),
//     'requests[1]': JSON.stringify({
//       url: 'members/banks.json',
//       extra_input: '',
//     }),
//   };
//   return conObj.post('multi.json', requestData).then((response) => {
//     if (response.status === 200) {
//       console.log('enter MULTI');

//       console.log(`multiResults = ${JSON.stringify(response.data?.data?.multiResults['members/banks.json'])}`);
//     }
//   });
// }

export function getUserMemberBalance() {
  return conObj.post('members/banks.json').then((response) => {
    const {
      balance,
      data: _memberBank,
      maxWithdrawLimit,
      minWithdrawLimit,
      currencyTopup,
      currencyWithdraw,
      profile,
      withdrawLimitMessage,
    } = response.data?.data;

    dispatch(
      setMemberBalance({
        currencyCode: balance?.currency,
        balance: balance?.balance,
        displayBalance: balance?.display_balance,
        canClearBalance: balance?.can_clear_balance,
      })
    );
    dispatch(setWithdrawRequirement(response.data?.data?.withdrawRequirement));
    if (_memberBank?.length > 0) {
      dispatch(setMemberBanks(_memberBank));
      dispatch(
        setDepositWithdrawMinMax({
          maxWithdrawLimit,
          minWithdrawLimit,
          currencyTopup,
          currencyWithdraw,
          withdrawLimitMessage,
        })
      );
    }

    dispatch(setUserProfile(profile));
  });
}

export function getInbox(page = 0, limit = 20) {
  return conObj.post(`notifications.json?page=${page}&limit=${limit}`).then((response) => {
    dispatch(setInbox(response.data.data.data));
  });
}

export function getUnreadInboxCount(page = 0, limit = 20) {
  return conObj.post(`unread_notifications.json?page=${page}&limit=${limit}`).then((response) => {
    dispatch(setUnreadInboxCount(response.data.data.data.count));
  });
}

export function getGameCategsAndCompanies() {
  return conObj.post('games/game_categories.json').then((response) => {
    dispatch(setGameCategsAndCompanies(response?.data?.data?.data));
    // const categories = response?.data?.data?.data;

    // const newCategories = [];

    // categories.forEach((category, index) => {
    //   if (index === 3) {
    //     // Client wants to reorder crash game
    //     // CRASH GAMES
    //     const crashGameCategs = {
    //       ...category,
    //       name: 'Crash Game',
    //       slug: 'crash-game',
    //       companies: [],
    //     };

    //     const instantGame = categories.find((x) => x.slug === 'instant-game');

    //     if (instantGame?.companies?.length) {
    //       instantGame.companies?.forEach((company) => {
    //         if (company.slug === 'whc-spribe-instant') {
    //           crashGameCategs.companies.push(company);
    //         }
    //       });
    //     }

    //     newCategories.push(crashGameCategs);
    //     newCategories.push(category);
    //   } else if (category?.slug === 'instant-game') {
    //     // INSTANT GAMES
    //     const instantGameCategs = {
    //       ...category,
    //       companies: [],
    //     };

    //     category.companies?.forEach((company) => {
    //       if (company.slug === 'whc-fastgame-instant') {
    //         instantGameCategs.companies.push(company);
    //       }
    //     });

    //     newCategories.push(instantGameCategs);
    //   } else {
    //     // OTHERS
    //     newCategories.push(category);
    //   }
    // });

    // console.log(newCategories);

    // dispatch(setGameCategsAndCompanies(newCategories));
  });
}

export function getGameCategs() {
  return conObj.post('games/categories.json').then((response) => {
    dispatch(setGameCategs(response?.data?.data?.data));
  });
}

export function getSiteSettings() {
  return conObj.post('setting/site_configs.json').then((response) => {
    let _popupBanner = [];

    if (response.data?.data?.data?.popups.length > 0) {
      _popupBanner = response.data?.data?.data?.popups.map((x) => ({
        showPopup: true,
        ...x?.BannerSlide,
      }));
    }

    const _siteSettings = {
      ...response.data?.data?.data?.configs,
      announcement_text: response.data?.data?.data?.announcement_text,
      sliders: response.data?.data?.data?.sliders,
      languages: response.data?.data?.data?.languages,
      memberGroupBanners: response.data?.data?.data?.member_group_banners,
      referralBanners: response.data?.data?.data?.referral_banners,
      popups: _popupBanner,
    };
    dispatch(setSiteSettings(_siteSettings));
    localStorage.setItem('site_settings', JSON.stringify(_siteSettings));
  });
}

export function getPromotions() {
  return conObj.post('promotion.json').then((response) => {
    dispatch(
      setPromotions({
        data: response.data?.data?.data,
        categories: response.data?.data?.categories,
        groupings: response.data?.data?.groupings,
      })
    );
  });
}

export function getAngPauPromotion() {
  return conObj
    .post('promotion.json', {
      promotion_type: 'angpau_manual',
    })
    .then((response) => {
      const respData = response.data?.data?.data;
      if (respData) dispatch(setAngPauPromos({ data: respData, isFetchedOnce: true }));
    });
}

export function getFakePlayerStats() {
  return conObj.post('setting/player_stats.json').then((response) => {
    if (response.data?.data?.data) {
      dispatch(setFakePlayerStats(response?.data?.data?.data?.latest_player_wins));
    }
  });
}

// pages.json is not yet supported by the api as of 18/12/2024
// export function multipleRequests() {
//   const requestData = {
//     'requests[0]': JSON.stringify({
//       url: 'setting/site_configs.json',
//       extra_input: '',
//     }),
//     'requests[3]': JSON.stringify({
//       url: 'pages.json',
//       extra_input: '',
//     }),
//   };

//   conObj.post('multi.json', requestData).then((response) => {
//     console.log(`multi.json = response`);
//   });
// }
